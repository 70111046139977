import React from "react"
import Seo from "../../components/seo"
import Layout from "../../components/layout"
import Title from "../../components/title"
import Button from "../../components/button"
import * as styles from "./404.module.scss"

export default function NotFound() {
  return (
    <>
      <Seo title="Page not found - Grycode" />
      <Layout showHeaderCtaButton>
        <section className={styles.section}>
          <header className={styles.header}>
            <span className={styles.notFound}>404</span>
            <Title headingLevel="h1">We sincerely apologize.</Title>
            <Title headingLevel="p">
              The page you are looking for does not exist.
            </Title>
            <Button to="/">Go to homepage</Button>
          </header>
        </section>
      </Layout>
    </>
  )
}
